.LinearizationMessageBox-container {
    min-height: 56px;
    width: 100%;
    border-width: 2px 2px 2px 16px;
    border-style: solid;
  }
  
  .LinearizationMessageBox-container-button {
    padding-left: 8px;
    border: none;
    background: none; 
  }
  
  .LinearizationMessageBox-container-text {
    font-family:  "IBM Plex Sans";
    font-weight: 600;
    font-style: normal;
    color: var(--black);
    margin-top: 10px;
    margin-bottom: 10px;
  }