.header-message {
    width: 514px;
    min-height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-width: 2px 16px 2px 16px;
    border-style: solid;
    flex-shrink: 0;
      
    .header-message-text {
        width: 100%;
        font-family:  "IBM Plex Sans";
        font-weight: 600;
        font-style: normal;
        color: var(--black);
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
