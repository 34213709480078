/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

.menu-item-row-label {
  display: flex;
  text-align: end;
  align-items: center;
  justify-content: flex-end;
  word-break: break-word;

  p {
    font-size: var(--font-size-lg);
  }
}
