/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
.menu-item-row-unit {
  display: flex;
  text-align: end;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: visible;

  p {
    font-size: var(--font-size-lg);
  }
}

