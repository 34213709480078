.quint4-image-selector-item-container {
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 5px;
    padding: 4px;

    .image-selector-item-container__label_container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        .image-selector-item-container__label_container__label {
            font-weight: 400;
            font-size: 18px;
            line-height: 100%;
            letter-spacing: 0%;
        }
    }

    .image-selector-item-container__image_container {
        width: 100%;
        display: flex;
        justify-content: center;

        .image-selector-item-container__image_container__image {
            padding: 2px;
            object-fit: contain;
            max-width: 90%;
            width: 200px;
            height: 200px;
        }      
    }
}

.quint4-image-selector-item-container--active {
    border: 4px solid rgba(0, 131, 138, 1);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}
