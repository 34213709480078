.backstage-feedback_inputs {
  display: grid;
  grid-template-columns: max-content 350px;
  grid-auto-rows: 50px;
  align-items: center;
  column-gap: 20px;
  margin-top: 20px;
}

.backstage-feedback_description {
  width: 1100px;
  height: 150px;
  padding: 10px;
  background-color: var(--lighter-gray);
  margin-top: -15px;
}

.backstage-feedback_choose-files {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: -15px;
}

.backstage-feedback_choose-files-hint {
  width: 1100px;
}

.backstage-feedback_choose-files-button {
  margin-top: 12px;
}

.backstage-feedback_choose-files-text {
  font-weight: bold;
}

.backstage-feedback_temp-message {
  color: red;
}

.backstage-feedback_files {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  height: fit-content;
  width: 1100px;
}

.backstage-feedback_user-agreement {
  display: flex;
  gap: 15px;
  margin-top: 30px;
  width: 1100px;
}

.backstage-feedback_user-agreement-text {
  display: flex;
  flex-direction: column;
}

.backstage-feedback_user-agreement-text-info {
  margin-top: 15px;
  font-size: 12px;
}

.backstage-feedback_submit-button {
  margin-top: 40px;
}