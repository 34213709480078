.gearbutton {
  button {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: var(--lighter-gray);
    border: 1px solid var(--dim-gray);
    border-radius: 25px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    img {
      width: 30px;
      height: 30px;
    }
  }
}