.startup-view-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-left: 64px;
    padding-right: 15px;

    .startup-view-header__text {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .startup-view-header__tools {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
  }
}
