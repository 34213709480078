.zoom-control {
  display: flex;
  align-items: center;
  height: 35px;
}

.zoom-control-percentage {
  margin-left: 8px;
  margin-right: 8px;
  font-size: 16px;
}

.zoom-control-button {
  all: unset;
  font-size: 18px;
  user-select: none;
}