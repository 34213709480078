.smallTable_separator-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-end;
    height: 24px;
    width: 100%;
    padding-bottom: 8px;
}

.smallTable_separator-container-separator {
    position: flex;
    align-self: flex-end;
    width: 268px;
    border: 1px solid var(--dim-gray);
}

.smallTable-container {
    width: 100%;
    height: 72%;
    flex-wrap: nowrap;
    overflow-y: scroll;
    padding-top: 8px;
}

.smallTable-container-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 16px;
    padding-right: 16px;
    column-gap: 28px;
}

.smallTable-container-data {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    column-gap: 28px;
    flex-wrap: nowrap;
    padding-top: 8px;
    margin-bottom: 10px;
}

.smallTable-container-data-element {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    padding-right: 16px;
}

/* Table with Kaltstelle */

.smallTableKaltstelle-container {
    width: 100%;
    height: 72%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: scroll;
}

.smallTableKaltstelle-container-table {
    width: 100%;
    height: 70%;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
}
