.fullTableView-container {
    display: flex;
    flex-direction: column;
    height: 584px;
    width: 745px;
}

.fullTableView-container-top {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    height: 80px;
}

.fullTableView-container-top-items {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: 680px;
}

.fullTableView-container_separator {
    position: flex;
    max-width: 745px;
    height: 1px;
    border: 1px solid var(--dim-gray)
}

.fullTableView-container-top-headeritem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-left: 36px;
    overflow: hidden;
}

.fullTableView-container-top-headeritem-label {
    font-family: "IBM Plex Sans";
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    height: 24px;
    color: var(--black);
    fill: solid;
    padding-right: 8px;
    white-space: nowrap;
  	overflow: hidden;
    min-width: 190px;
}

.fullTableView-container-top-headeritem-inputbox {
    min-width: 85px;
    width: 85px;
}

.fullTableView-container-top-icon-container {
    position: absolute;
    left: 94.5%;
}

.fullTableView-container-top-icon-container:focus-visible {
    outline-color: var(--button-border) !important;
}

.fullTableView-container-top-icon-container-icon {
    width: 32px;
    height: 32px;
}

.fullTableView-container-top-expand-overlay {
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.fullTableView-container-top-expand-container {
    top: -8%;
    right: 5%;
    position: absolute;
    z-index: 20000;
    width: 194px;
    background: var(--white);
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    align-self: stretch;
    padding: 4px;
}

.fullTableView-container-bottom-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 50px;
    padding-top: 16px;
    column-gap: 28px;
}

.fullTableView-container-bottom {
    height: 430px;
    max-width: 230px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    column-gap: 28px;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.fullTableView-container-bottom-element {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    width: 230px;
}

.fullTableView-container-bottom-range-warning {
  width: 100%;
  margin-bottom: 10px;
  margin-left: 16px;
  padding-right: 10px;
  color: red;
}